const resources = [
    {
        id:0,
        type: 0,
        icon:"document",
        name: "Building a Missions Partnership From the Ground Up",
        url: "/resources/BuildingaMissionsPartnership.pdf",        
    },
    {
        id:1,
        type: 1,
        icon: "mic",
        name: "A New Chapter For Vineyard Missions (We Are Vineyard Podcast)",
        url: "https://episodes.castos.com/618cb9ae310d08-75081356/bc4f1326-3acb-459e-a996-25c0359ddf6c-WAV-Bubba-Justice-S1E37.mp3",        
    },
    
    {
        id:2,
        type: 2,
        icon: "",
        name: "T Bar M Property Map",
        url: "/resources/PropertyMap.jpg",  
        ratio: "4by3"      
    },
    {
        id:3,
        type: 2,
        icon: "",
        name: "T Bar M Room Layout",
        url: "/resources/RoomLayout.jpg",        
        ratio: "4x5"
    },
    {
        id:4,
        type: 1,
        icon: "images",
        name: "Compassion International Slides (Session 2)",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vQ3x2N4MaXAP4XGxj7oEbls9WkASwK2sxzA1rax0QfEoRvZZro6KTDUfIp6Rr6v2Q/pub?start=false&loop=false&delayms=60000" 
    },
    {
        id:5,
        type: 1,
        icon: "images",
        name: "I-61 Ministries",
        url: "https://www.i-61.org/network-partners" 
    },
    {
        id:6,
        type: 1,
        icon: "link",
        name: "I-61 Ministries Slides (Session 2)",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vTJVhela0KF02iuKB3gJtOF4gvqMxuiVGywTrYbf9_esEx6W2AC88rQcrySCMIhCw/pub?start=false&loop=false&delayms=60000" 
    },
    {
        id:7,
        type: 1,
        icon: "images",
        name: "Biblical Justice Slides (Session 2)",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vRyCfcRf0OOXKzdpYlBsekVOLxFzWqGOyGn_QEaqLiRm_gLkPfImoeW5kSVB4X26w/pub?start=false&loop=false&delayms=60000" 
    },
    {
        id:8,
        type: 1,
        icon: "images",
        name: "Convoy of Hope Slides (Session 2)",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vTYh5WyVHWbrbMM7SIDdZvglegQ1HaF3933TwvWPqd3gXdVWMYdJ2BY-RJsdiQolg/pub?start=false&loop=false&delayms=60000" 
    },
    {
        id:9,
        type: 1,
        icon: "images",
        name: "Cultivating and Responding to the Holy Spirit in Missions - Bubba Justice (Session 3)",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vSxE0wJq-jh0VEEkDLIZdmKSpdVmUm6rET6K11D3PQk5rClhLYQ4r3m4MHrZ5XLEA/pub?start=false&loop=false&delayms=60000" 
    },
    {
        id:10,
        type: 1,
        icon: "images",
        name: "Training Spotlight - Jim Egli",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vTdA16FkLoGuI2o0vbHnZ1Psb355XlcHOprFgTqx1Y8Fh_0fLEnkB_uti3zMIPg-w/pub?start=false&loop=false&delayms=60000" 
    },
    {
        id:11,
        type: 1,
        icon: "images",
        name: "Grapes and Raisins - Naylor-Tattersons",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vQaG2nlug7Ry4rFMnj97tkW5tBmIJBGaQLgRy0xSnaunL_02DTm8M6KbZNknCmILg/pub?start=false&loop=false&delayms=60000" 
    },
    {
        id:12,
        type: 1,
        icon: "document",
        name: "Vineyard USA Playbook",
        url: "/resources/VUSAPlaybook.pdf" 
    },
    {
        id:13,
        type: 1,
        icon: "videocam",
        name: "Convoy of Hope - Hurricane Ian",
        url: "https://vimeo.com/convoyofhope/review/755836009/b1fc733479" 
    },
    {
        id:14,
        type: 1,
        icon: "videocam",
        name: "The State of the Great Commission",
        url: "https://www.youtube.com/watch?v=ocet836tYgY&feature=youtu.be" 
    },
    {
        id:15,
        type: 1,
        icon: "videocam",
        name: "I-61",
        url:"https://youtu.be/ery3ofpwCEE"
    }

    
]

export default resources;