import data from "../data/data";
import "./Maps.css";

function Maps(){

    const mapList = data.getMaps();
    
    return(
        <div className="maps fade-in">
            
        <section className="container">
            <h1 className="title has-text-centered">T Bar M Maps</h1>
            <ul className="">
                {data.getMaps().map((r)=>
                <li key={r.id}>
                    <p>{r.name}</p>
                    <label>(Click to zoom in)</label>
                    <figure className={`image is-${r.ratio}`}>
                        <a href={r.url}><img className="invisible" onLoad={(e)=>e.target.classList.remove("invisible")} src={r.url}/></a>
                    </figure>
                </li>
                )}
            </ul>
            
        </section>
            
        

    </div>
    )
}

export default Maps;