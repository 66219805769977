import "./Bio.css";

function Bio(props){
    return(
        <div className={`bio modal ${props.isActive ? "is-active modal-fade-in" : "modal-fade-out"}`}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className="box main">
                    <h2>{props.bio.name}</h2>
                    <h3>{props.bio.title}</h3>
                    <br/>
                    <p>
                        {props.bio.bio}
                    </p>
                    <br/>
                    <button className="button is-success close" onClick={props.close}>Close</button>
                </div>
            </div>
            <button className="modal-close is-large" onClick={props.close}  aria-label="close"></button>
        </div>
    )
}

export default Bio;