
function Spacer(props){
    const styles = {
        minHeight:`${props.size}em`,
        display: props.dontShow ? "none" : "block"      
    }
    return(
        <div style={styles}></div>
    )

}

export default Spacer;