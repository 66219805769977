export const deviceType = {
    isMobile: function () {
      return this.isMobileLandscape();
    },
    isMobilePortrait: () => {
      return window.matchMedia("(max-width:450px)").matches;
    },
    isMobileLandscape: () => {
      return window.matchMedia("(max-width:900px)").matches;
    },
    
  };