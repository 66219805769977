import { useEffect, useState } from "react";
import {deviceType } from '../util/deviceType';
import BreakoutMeeting from "../BreakoutMeeting/BreakoutMeeting";
import Spacer from "../Spacer";
import './Schedule.css';
import data from "../data/scheduleData";

function Schedule(){
    const panelVisibilty = data.map((panel)=>{
        return {id:panel.id, isVisible:false, date:new Date()}
    });
    const [panels, setPanels] = useState(panelVisibilty);
    const [regionDetails, setRegionDetails] = useState({"day":"", regions:[]});
    const [showBreakout, toggleShowBreakout] = useState(false);
    
    

    useEffect(()=>{               
        const today = new Date().getDay();
        const updatedPanels = data.map((panel)=>{
            if(deviceType.isMobilePortrait()){
                if(panel.date.getDay() === today){
                    return {id:panel.id, isVisible:true}
                }
                else{return panel}
            }
            else
            {
                return {id:panel, isVisible:true}
            }
        })
        setPanels(updatedPanels);     
        
    },[]);


    const togglePanel = (panelId)=>{
        const updatedPanels = panels.map((panel)=>{
            if(panel.id === panelId){
                return {id:panelId, isVisible:!panel.isVisible}
            }
            else{return panel}
        })
        setPanels(updatedPanels);
        
    }

    const showRegionDetails = (regions, day)=>{
       const updatedDetails = {
            day,
            regions
        }
        setRegionDetails(updatedDetails);    
        toggleShowBreakout(true);   
    }

    const scrollToDay = (panel, evt)=>{
        if(deviceType.isMobile()){
            if(!panel.isVisible){
                evt.target.scrollIntoView({behavior:"smooth"});
            }
            
        }
    }

    
    const scheduleList = data.map((item)=>
        <div className="day" key={item.id} id={`schedule-day-${item.id}`}>
        <article className={`panel is-primary container`} onClick={(e)=> {deviceType.isMobile() && togglePanel(item.id); scrollToDay(item, e)}}>
        <p className="panel-heading control has-icons-right">                        
            {item.day}
            <span className="icon is-right"><ion-icon name={`chevron-${panels[item.id].isVisible ? "up" : "down"}-outline`}></ion-icon></span>
            </p>
            <div className="spacer"></div>
        <section className= {`schedule-detail ${panels[item.id].isVisible ? "expand" : "collapse"}`}>
        {item.sessions.map((session)=>
                <div key={item.id + session.timeOfDay}>                            
                <h3 className="has-text-weight-bold has-text-primary has-text-centered is-uppercase mt-3">{session.timeOfDay}</h3>
                <hr className="mb-0 mt-1"></hr>
                
                <table className="table">
                    <tbody>
                    {session.meetings.map((meeting, index)=>
                        <tr key={index}>
                            <td><ion-icon name={meeting.icon}></ion-icon></td>
                            <td className="has-text-weight-bold meeting-name">{meeting.name}: </td>
                            <td>{meeting.time}</td>
                            <td className={meeting.hasDetails && "button-details"}>{meeting.hasDetails ? <button className="details" onClick={(evt)=>{evt.stopPropagation();showRegionDetails(meeting.details, item.day)}}>{meeting.location}</button> : <p>{meeting.location}</p>}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <Spacer size="1" />
                </div>
                
            )}
             
        </section>
      
         </article>
        </div>
    )
    return(
        <div className="container schedule route-component fade-in">

        <div className="days">
            {scheduleList}
        </div>
        <BreakoutMeeting isActive={showBreakout} details={regionDetails} close={()=>toggleShowBreakout(false)} />
        
    </div>
    )

}

export default Schedule;