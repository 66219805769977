import { useEffect, useState } from "react";
import "./Home.css";
import Spacer from "./Spacer";
import { deviceType } from "./util/deviceType";
import { Outlet, useNavigate } from "react-router-dom";


function Home() {

    const [activeView, updateActiveView] = useState(-1);
    const navigate = useNavigate();
    useEffect(()=>{
        const currentRoute = window.location.pathname;
        switch (currentRoute) {
            case "/home/schedule" :
                updateActiveView(0);
                break;
            case "/home/speakers" :
                updateActiveView(1);
                break;
            case "/home/mlt" :
                updateActiveView(2);
                break;
        }
    })
    return(
        <div>
            <section id="mainMenu">
                <div className={deviceType.isMobile() ? "tabs is-medium is-centered" : "tabs is-large  is-centered"}>
                    <ul>
                        <li className={activeView === 0 ? "is-active" : ""} onClick={()=>{navigate("schedule")}}>
                            <a href="/" onClick={(e)=>{e.preventDefault()}}>
                            <span className="icon is-small"><ion-icon name="calendar-outline"></ion-icon></span>
                            <span>{!deviceType.isMobile() && "Daily" } Schedule</span>
                            </a>
                        </li>
                        <li className={activeView === 1 ? "is-active" : ""} onClick={()=>{navigate("speakers")}}>
                            <a href="/" onClick={(e)=>{e.preventDefault()}}>
                            <span className="icon is-small"><ion-icon name="people-outline"></ion-icon></span>
                            <span className="">Speakers</span>
                            </a>
                        </li>                        
                        <li className={activeView === 2 ? "is-active" : ""} onClick={()=>{navigate("mlt")}}>
                            <a href="/" onClick={(e)=>{e.preventDefault()}}>
                            <span className="icon is-small"><ion-icon name="earth-outline"></ion-icon></span>
                            <span>{deviceType.isMobile() ? "MLT" : "Missions Leadership Team"}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
            <Spacer size={deviceType.isMobile ? "2" : "6"}  />
            {/* <section className={activeView == 0 ? "fade-in" : "fade-out hide"} id="schedule">
                <Schedule />
            </section> */}
            {/* <section className={activeView == 1 ? "fade-in" : "fade-out hide"} id="speakers">
                <Speakers />
            </section>             */}
            {/* <section className={activeView == 2 ? "fade-in" : "fade-out hide"} id="mlt">
               <MLT />
            </section> */}
            <Outlet/>
        </div>
        )
}



export default Home;