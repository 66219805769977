import Spacer from '../Spacer';
import "./Resources.css";
import data from "../data/data";
import ResourceView from '../ResourceView/ResourceView';
import { useState } from 'react';

function Resources(){
    
    return(
        <div className="resources fade-in">
            
            <section className="container">
                <h1 className="title has-text-centered">MLM Resources</h1>
                <table className="table narrow">
                    <tbody>                    
                    {data.getResources().map((r)=>
                    <tr key={r.id}>
                        <td><ion-icon name={r.icon}></ion-icon></td>
                        <td><a target="_blank" href={r.url}>{r.name}</a></td>
                    </tr>
                    )}
                    </tbody>
                </table>
                <Spacer size="1" />
            </section>
        </div>
        
    )
}

export default Resources;