import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Schedule from './Schedule/Schedule';
import Speakers from './Speakers/Speakers';
import MLT from './MLT/MLT';
import Resources from './Resources/Resources';
import './main.scss';
import reportWebVitals from './reportWebVitals';
import Home from './Home';
import Maps from './Maps/Maps';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Navigate to="home/schedule"/>} />     
          <Route path='/' element={<App/>}>
            <Route path='/home' element={<Home/>}>
              <Route path='schedule' element={<Schedule/>} />
              <Route path='speakers' element={<Speakers/>} />
              <Route path='mlt' element={<MLT/>} />
            </Route>
            <Route path='/resources' element={<Resources/>}/>
            <Route path='/maps' element={<Maps/>}/>
          </Route>   
      </Routes>
    </BrowserRouter>
    
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
