import "./BreakoutMeeting.css";

function BreakoutMeeting(props){

    return(
        <div className={`breakout-meeting modal ${props.isActive ? "is-active modal-fade-in" : "modal-fade-out"}`}>
            <div className="modal-background" style={{opacity:0.8}}></div>
            <div className="modal-content">
                <div className="box main">
                <button onClick={props.close} className="back">
                    <span className="icon is-large">
                        <ion-icon name="arrow-back-outline"></ion-icon>
                        Back
                    </span>
                    </button>
                    <h2>Regional Meetings</h2>
                    <h3>{props.details.day}</h3>
                    <div className="details">
                        <table className="table">
                            <tbody>
                                {props.details.regions.map((region)=>
                                    <tr key={region.region}><td>{region.region}</td><td>{region.location}</td></tr>
                                )}                  
                            </tbody>
                            
                        </table>
                    </div>
                   
                    
                </div>
             
            </div>
            <button className="modal-close is-large" onClick={props.close}  aria-label="close"></button>
        </div>
    )
}

export default BreakoutMeeting;