import './App.css';
import Home from './Home';
import { Outlet } from 'react-router-dom';
import Footer from "./Footer/Footer";
import MainHeader from './MainHeader/MainHeader';
import Spacer from './Spacer';
import {deviceType} from './util/deviceType';
import { useEffect } from 'react';


function App() { 
  useEffect(()=>{
    const appHght = document.querySelector(".App").clientHeight;
    document.querySelector(".App").style.minHeight = `${appHght}px`;

  },[])
  return (
    <div className="App">
      <MainHeader/>
      <Spacer size={deviceType.isMobile() ? "3" : "3"} />
      <div className="app-content">              
        <Outlet/>
      </div>            
      <Footer/>
    </div>
  );
}

export default App;
