import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

function Footer(){

    const [activeView, updateActiveView] = useState(-1);
    const navigate = useNavigate();
    useEffect(()=>{
        const p = window.location.pathname;
        const dictionary = new Map();
        dictionary.set('/home/schedule', 0);
        dictionary.set('/home/speakers', 0);
        dictionary.set('/home/mlt', 0);
        dictionary.set('/resources/', 1);
        dictionary.set('/maps', 2);
        updateActiveView(dictionary.get(p));        
    }, [])
    return(
        <footer className="footer-main">
            <ul>
                <li className={activeView === 0 ? "is-active" : undefined} onClick={()=>{updateActiveView(0); navigate("home/schedule")}}>
                    <ion-icon name="home-outline"></ion-icon><br/>                    
                    <a href="/" className={activeView === 0 ? "is-active" : undefined} onClick={(e)=>{e.preventDefault()}}>Home</a>
                </li>
                <li className={activeView === 1 ? "is-active" : undefined} onClick={()=>{updateActiveView(1); navigate("resources")}}>
                    <ion-icon name="document-text-outline"></ion-icon><br/>
                    <a href="/" className={activeView === 1 ? "is-active" : undefined} onClick={(e)=>{e.preventDefault()}}>Resources</a>
                </li>
                <li className={activeView === 2 ? "is-active" : undefined} onClick={()=>{updateActiveView(2); navigate("maps")}}>
                    <ion-icon name="map-outline"></ion-icon><br/>
                    <a href="/" className={activeView === 2 ? "is-active" : undefined} onClick={(e)=>{e.preventDefault()}}>Maps</a>
                </li>
            </ul>
        </footer>
    )
}

export default Footer;