
import { useEffect, useState } from "react";
import Bio from "../Bio/Bio";
import './Speakers.css';
import data from "../data/data";



function Speakers(){
    const [bioView, updateBioView] = useState(false);
    const [bioDetails, setBioDetails] = useState({name:"", bio: "", title:""});
    
    const readMoreStyle = {
        backgroundColor:"transparent",
        color: "var(--blueAlt)",
        border:"none",
        fontSize:"1em",
        cursor:"pointer"
        
    }

    const openBio = (person)=>{
        setBioDetails(person);
        updateBioView(true);
    }

    
    return(
        <div className="container speakers fade-in route-component">
            <div className="speaker-list">
            {data.getSpeakers().map((p)=>
                   <article key={p.id} className="speaker">
                <div className="card">
                    <div className="card-content">
                    
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-128x128">
                                <img className="invisible" onLoad={(e)=>e.target.classList.remove("invisible")} alt={`${p.name} avatar`} src={`/img/bios/${p.img}`}/>
                            </figure>
                        </div>
                        <div className="mediaContent">
                            <p className="title is-4">{p.name}</p>
                            <p className="subtitle is-6">{p.title}</p>
                        </div>
                    </div>

                    <div className="content">
                   <article className="content-bio">
                       <p>
                          {p.bio}
                          
                        </p>
                   </article>
                  <br/>
                   <button className="read-more" style={readMoreStyle} onClick={()=>openBio(p)}>Read More</button>
                    </div>
                    </div>
                </div>
            </article>
            )}
         
       
        </div>
        <Bio isActive={bioView} bio={bioDetails} close={()=>updateBioView(false)} />
        </div>
        
    )
}

export default Speakers;