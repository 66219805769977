import Spacer from "../Spacer";
import { deviceType } from "../util/deviceType";
import "./MLT.css";
import data from "../data/data";
import Bio from "../Bio/Bio";
import { useState } from "react";

function MLT(){
    
    const [bioActive, setBioActive] = useState(false);
    const [bio, setBio] = useState({name:"", title:"", bio:""})

    const openBio = (details)=>{
        setBio({name:details.name, title:"", bio:details.bio});
        setBioActive(true);
    }

    const emailMlt = (leader)=>window.open(`mailto:${leader.email}?subject=MLM Connection`);
    
    return(
        <div className="container mlt route-component fade-in">
            {deviceType.isMobile() ? <h2 className="subtitle">Missions Leadership Team</h2> : "" }
             <Spacer size={deviceType.isMobile() ? "0" : "4"}/>

             <div className="mlt-list is-variable is-4">
                 {data.getMLT().map(m=>
                    <article className="leader" key={m.id}>
                     <div className="card">
                        <div className="card-content">
                            
                            <div className="media">
                                <div className="media-left">
                                    <figure className="image is-128x128">
                                        <img className="invisible" onLoad={(e)=>e.target.classList.remove("invisible")} alt={`${m.name} avatar`} src={`/img/bios/${m.img}`}/>
                                    </figure>
                                </div>
                                <div className="mediaContent">
                                    <p className="title is-4">{m.name}</p>
                                    <p className="subtitle is-6 mb-1 has-text-grey-dark">{m.title}</p>
                                    <button className="open-bio" onClick={(e)=>openBio(m)}>Read Bio</button><br/>
                                    
                                </div>
                            </div>

                        </div>

                        <div className="card-footer">
                                <button className="button is-success" onClick={()=>emailMlt(m)}><ion-icon name="mail-outline"></ion-icon>&nbsp;&nbsp;{`Email ${m.name.split(' ')[0].replace("Elgie", "Bubba")}`}</button>
                            </div>  
                     </div>
                   </article>                    
                   
                 )}
                  
             
             </div>
              
              <Bio isActive={bioActive} bio={bio} close={()=>setBioActive(false)}  />

        </div>
    )

}

export default MLT;