const people = [
    {
        id: 0,
        name: "Jay Pathak",
        title: "National Director",
        img: "JayPathak.jpg",
        email: "",
        bio:`Jay Pathak is the National Director of Vineyard USA and the Senior Pastor of the Mile High Vineyard. He strategically guides the mission 
        and vision of Vineyard USA while supporting the hundreds of churches associated with the movement by leading and directing the National Team. 
        He has spoken nationally and internationally for Vineyard and other groups in both conference and classroom settings. Jay co-wrote the book, 
        The Art of Neighboring, with Dave Runyon. His passion for sharing his faith with the people around him is at the center of his calling.
        Jay is a humble, empowering leader who is passionate about raising up leaders around him. His strengths, in particular, lie in strategically
        placing people on teams where they thrive. Additionally, he regularly thinks of ministry in context of culture and developing relationships and 
        partnerships in the city, government, and across denominational lines. Jay has a BA in Philosophy from The Ohio State University and is a 
        graduate of the Vineyard Leadership Institute. He and his wife, Danielle, were married in 1998 and have two daughters, Jasmine and Sofia.`
        
    },
    {
        id: 1,
        name: "Phil Strout",
        title: "Global Vineyard",
        img: "PhilStrout.jpg",
        email:"",
        bio:`Phil Strout was the Vineyard USA’s previous National Director and is a committed leader and communicator in the 21st century church. 
        He brings over thirty-five years of experience in the areas of church planting and cross-cultural missions. Referred to as a “quintessential practitioner” 
        for his work in Latin America and globally, Phil is not afraid to think outside the box and challenges others to do the same. Phil is a leadership coach as 
        well as the author of God’s Relentless Pursuit: Discovering His Heart for Humanity and THRIVE: How to Successfully Navigate the S-Turns of Leadership. `
        
    },
    {
        id: 2,
        name: "Josh Williams",
        title: "Associate National Director for Evangelism and Justice",
        img: "JoshWilliams.jpg",
        email:"",
        bio:`Josh Williams is the Associate National Director for Evangelism and Justice for Vineyard USA and is the Lead Pastor of the Elm City Vineyard Church in New Haven, CT. 
        He resources and equips Vineyard pastors as they lead their churches in proclaiming and demonstrating the good news of the Kingdom of God to the lost, the poor, the sick, and the marginalized. 
        Josh has served as an Area Leader and an Executive Team member. He has also been on the Vineyard Ethnic Diversity task force since 2019. One of Josh’s favorite Vineyard memories is helping to 
        lead a Diverse Leaders Gathering in 2019 for leaders of color to connect with one another, be refreshed, and be inspired by God’s Spirit to thrive, not just survive in our movement. Josh graduated 
        from Yale and has an M.Div. from Yale Divinity School. Josh and his wife Tina recently celebrated ten years of marriage and are also parents of two adorable little ones, Zoe (3) and Joy (1). 
        Their favorite family activities are going to waterfalls and beaches in Connecticut, eating ice cream, and singing loudly together.
        `
    },
    {
        id: 3,
        name: "Bryan McLees",
        title: "Network Relations Director with Convoy of Hope",
        img: "BryanMcLees.jpg",
        email:"",
        bio:`
        (Strategic Partner)
        Bryan McLees is the Network Relations Director with Convoy of Hope. He is a graduate of Fuller Seminary, 
        and has worked with Convoy of HOPE since May of 2006. He and his wife Barbara, Son Henry, and Bryan’s mother Ellen, live together in Loveland, Colorado. 
        Over the past 38 years Bryan has played a key role in 4 Vineyard church plants. The first church he planted was in the Boston area with the founder of the first Vineyard churches, 
        Kenn Gulliksen. As a noted practitioner, Bryan was invited to share in learning—community with writers, and scholars that included the late Dallas Willard. Together the group articulated 
        the dynamic role of service to the poor, and relational transparency, in spiritual formation. The principals gleaned from these friends, and experiences, are the texture 
        and paradigm for what he offers as a teacher—speaker . The best thing about Bryan is his authentic delight in others.        
        `
    },
    {
        id: 4,
        name: "Guy Wasko",
        title: "Church Partnership Manager with Compassion International",
        img: "GuyWasko.jpg",
        email:"",
        bio:`
        (Strategic Partner)
        Guy Wasko is a Church Partnership Manager with Compassion International. In this role he has the privilege of serving pastors and churches, all for the purpose of 
        releasing children from poverty in Jesus’ name. Prior to joining Compassion he planted and pastored a Vineyard church in the Lower East Side of Manhattan. 
        With over 22 years of pastoral leadership experience Guy loves to see people, organizations and churches thrive, collaborate and live on mission. He and his wife 
        Rebecca have been married for 22 years and have three daughters, a dog named Wrigley, and love that they get to call NYC home.
        `
    },
    {
        id: 5,
        name: "Elgie “Bubba” Justice Jr.",
        title: "Missions Coordinator and CFO",
        img: "BubbaJustice.jpg",
        email:"bubba@vineyardusa.org",
        bio:`
        Elgie “Bubba” Justice begin serving as the Missions Coordinator on July 1, 2021. In his capacity as the Missions Coordinator, he gives leadership to VUSA Missions. 
        He oversees mobilization, training, partnership development and coordinating US activity with other Vineyard movements. He also serves as the 
        Chief Financial Officer of Vineyard USA (VUSA). In his capacity as CFO, Bubba supports the National Director, Jay Pathak, by overseeing all phases of VUSA 
        finances which includes Vineyard Resources, Vineyard Worship and Lilly Grants. From 2017 until 2021 Bubba served as the National Coordinator for VUSA. 
        Prior to working with VUSA, Bubba was the senior pastor of the Inverness Vineyard Church (IVC) for 23 years and before that worked in the financial world as a C.P.A., 
        a financial manager, and chief financial officer. Bubba and his wife Melany live in Houston, TX, and have two married daughters. 
        `
    },
    {
        id: 6,
        name: "Ross Naylor-Tatterson",
        title: "Regional Coordinator for South & East Asia",
        img: "Ross.jpg",
        email:"ross@vmteam.org",
        bio:`
        Ross is a Pastor in West Michigan. After working with YWAM for a decade, Ross served as the director of youth and family for 
        Churchlands Vineyard Christian Fellowship in Perth, and Executive Pastor of the Lakeshore Vineyard for five years and then struck out to begin a new church plant. 
        Now Ross juggles ministry at his church, developing coaches within an assembly manufacturing plant and part-time consulting in his company 
        Inlive for Enneagram self-awareness training. He is spreading the vision of the Community of Hope - uniting church, youth and business for the Kingdom. 
        He is now thoroughly enjoying being a grandfather of five. He serves as the Regional Coordinator for Asia for Vineyard Missions USA.
        `
    },
    {
        id: 7,
        name: "Barbara Naylor-Tatterson",
        title: "Spiritual Formation Specialist",
        img: "Barbara.jpg",
        email:"barbnt7@gmail.com",
        bio:`
        Barbara Naylor-Tatterson currently resides in Holland, Michigan where she and her husband pastor a Vineyard Church. She has 5 adult children, and 8 grandchildren 
        that fill her life with loads of laughter. Barbara also loves to sit with people and help them notice what the Father may be doing and inviting them into. 
        She has a heart for the nations and love for those that may be feeling tired, weary, broken or on the outside. 
        In her spare time, you will find her tending her flowers or curled up with a cup of tea/coffee and a good book.
        `
    },
    {
        id: 8,
        name: "Terra Montford",
        title: "Compassion / Justice Specialist",
        img: "Terra.jpg",
        email:"terram@vmteam.org",
        bio:`
        Terra currently serves as Missions Pastor & i-61 Ministries Director at the Vineyard Boise in Idaho. Along with her husband Michael, 
        she co-leads the Vineyard USA Missions partnership for Myanmar and has focused on planting dozens of churches in Myanmar that tangibly 
        express core values of justice and compassion. Terra has been involved in missions work for the past 20 years and is excited about seeing 
        God continue to use her and the i-61 Ministries to mobilize and equip local churches for justice and compassion ministry worldwide. She also enjoys being mom to her 
        two school-aged kids and raising two adorable puppies. When her husband isn’t building treehouses or hobbit holes in his spare time, they like to travel and explore the beautiful hot springs of Idaho.
        `
    },
    {
        id: 9,
        name: "Ray Maldonado Jr.",
        title: "Regional Coordinator for the Caribbean",
        img: "Ray.jpg",
        email:"raym@vmteam.org",
        bio:`
        Rafael (Ray) Maldonado Jr. began in ministry in 1978, planting a church in 1983. He was the senior pastor for two Chicago churches for over 28 years. He has earned a Master’s and Doctorate of Ministry. 
        Ray has been involved in missions for over 16 years. He leads the VM Puerto Rico and Cuba partnerships and was recently appointed as VM Coordinator for the Caribbean Region. 
        Ray and his wife Teresa have 5 children and 10 grandchildren and now reside in Florida.
        `
    },
    {
        id: 19,
        name: "Rich Andrews",
        title: "Co-Regional Coordinator for Latin America",
        img: "Rich.jpg",
        email:"richa@vmteam.org",
        bio:`
        Rich Andrews first met the Vineyard 20 years ago at the opening service of a new Vineyard plant in the Philadelphia area. 
        At the time, Rich was a student at the University of Pennsylvania, where he received a Master’s degree in education. 
        Rich then completed Vineyard Institute and became an associate pastor at that church plant now known as Blue Route Vineyard. 
        After seven years as a pastor, Rich and his wife Jess, along with their four children, and a handful of people went out in 
        2014 and planted the Conshohocken Vineyard, just west of Philadelphia. Since 2009, Rich also has been leading the Vineyard 
        Bolivia Partnership. He has a vision to see healthy Vineyard churches planted throughout South America, where he travels
        regularly to encourage church planters, train pastors, and equip leaders. In his spare time, Rich can be found working out at 
        the CrossFit box, eating healthy food, and unhealthy food, or hanging out with his family.
        `
    },
    {
        id: 11,
        name: "Jeremy Pleasant",
        title: "Co-Regional Coordinator for Sub-Saharan Africa",
        img: "Jeremy.jpg",
        email:"jeremyp@vmteam.org",
        bio:`
        Jeremy began with the Vineyard in 2007. From working with youth, playing with worship teams, to eventually becoming an 
        Associate Pastor, he has continually desired to serve his church and community Jeremy is now the Senior Pastor of the 
        Vineyard Church of Baton Rouge. In addition to his ministry background, Jeremy has professional experience in Creative 
        Arts and Technology industries, as well as a background in entrepreneurship. He also leads the Vineyard Haiti Partnership,
         where he leads, in partnership with other organizations, an international mission that focuses on planting churches and 
         training leaders and equipping Haitians to train and empower their own people to build sustainable ministries. 
         He is deeply committed to justice, compassion, and healing in his local community and communities impacted by
        poverty and injustice.
        `
    },
    {
        id: 12,
        name: "Josh Armstrong",
        title: "Co-Regional Coordinator for Sub-Saharan Africa, Missions Mobilizer",
        img: "Josh.jpg",
        email:"josha@vmteam.org",
        bio:`
        Josh is married to his high school sweetheart Amanda, and they have two children that keep them on their toes. 
        They have been a part of the Vineyard Church in East Tennessee for over a decade. Josh has had several ministry positions 
        over the years, which include being a regional coordinator for the Fellowship of Christian Athletes, a campus chaplain at a 
        Christian high school, and a youth pastor position. Josh and his family also spent 3 years as missionaries in Haiti, where 
        they helped launch several projects, including a disciple making movement that is still going strong. Josh currently serves 
        as the missions pastor at the Vineyard Church in East Tennessee, where he helps to catalyze and develop disciple making 
        movements in several areas both nationally and internationally.
        `
    },
    {
        id: 13,
        name: "Michael Palandro",
        title: "Regional Coordinator for North Africa, Middle East, & Central Asia",
        img: "Michael.jpg",
        email:"michaelp@vmteam.org",
        bio:`
        Michael served as a pastor for 36 years at the Vineyard Church of Houston, 25 of those as the lead pastor until retirement 
        in 2019. Over the years, he served as a stateside missions coordinator and then as the USA missions coordinator for Latin 
        America. At the partnership level, he has led the partnerships for both Turkey and Colombia. More recently he served 
        with Vineyard’s “Pastor’s Well-being Project,” as a mentor to transitioning pastors. In 2020, he completed a Doctor 
        of Ministry project aimed at promoting spiritual formation in the Vineyard by prioritizing scripture-reading and the 
        practice of lectio divina. This resulted in the formation of lectio4life.com, as a resource for people seeking to
        better incorporate scripture into their spiritual practices. Michael and Donna have been married since 1975. They have one son, 
        John-Mark, who is married to Holly and have three grandkids - Selah, Judah, and Zephaniah. Michael and Donna live in Houston 
        but also love the Northeast where Michael grew up and where they visit frequently.
        `
    },
    {
        id: 14,
        name: "Darrell Evans",
        title: "Coaching Specialist",
        img: "Darrell.jpg",
        email:"darrell@vmteam.org",
        bio:`
        Darrell served as the Pastor of Oasis Vineyard Church in Salt Lake City, Utah for over 30 years. 
        Darrell has a BA in Ministry and is currently working with the churches in Mexico and Cambodia. 
        He is a trained coach, and as Coordinator for Coaching, he is working with Bob Logan of Logan Leadership to build a 
        team of coaches to support the missions leadership within the Vineyard movement
        `
    },
    {
        id: 15,
        name: "Glenn Schroder",
        title: "Co-Regional Coordinator for Latin America, Missions Mobilizer",
        img: "Glenn.jpg",
        email:"glenn@cascadevineyard.org",
        bio:`
        Glenn Schroder has been part of the Vineyard movement since 1976 when he started attending a home group in Yorba Linda, 
        California that eventually became the Anaheim Vineyard. Glenn served there as the youth pastor under John Wimber from 1984-1996.
        Glenn and his wife, Donna, then planted the Cascade Vineyard in 1996, where they continue to serve today. Glenn has always had a 
        heart for youth and missions and has led numerous youth teams on missions trips and helped to organize Extreme Mission Conferences 
        in the early 2000s. He and Donna have 4 adult children and 6 grandchildren, all of whom live in the Portland area. In 2020, Glenn 
        published his first book, Never Trust A Leader Without A Limp, about the ministry of John Wimber. He enjoys kayaking with Donna, 
        Trail Blazer basketball, and cooking- especially if it involves fire and meat.
        `
    },
    {
        id: 16,
        name: "Duke and Marie Lancaster",
        title: "Missions Mobilizers",
        img: "DukeMarie.jpg",
        email:"dukel@vmteam.org",
        email2:"mariel@vmteam.org",
        bio:`
        Duke and Marie Lancaster have been in the Vineyard since 1992. Marie grew up in a Hungarian household, the daughter of an 
        Assembly of God pastor and church planter. Marie says that she knew she never wanted to be married to a pastor and when she 
        met Duke while they were attending Oral Roberts University she was certain he was the last person God would ever call into 
        ministry, so she married him. God used a mission trip to Colombia to radically impact Duke and he felt God call him to pastor. 
        Together they embraced a call to ministry and missions and never looked back. They have traveled the world doing ministry and 
        Duke has led the Colombia partnership for the past 5 years. They have planted 3 churches and were the Senior Pastors of 
        Vineyard Church of Jackson, Mississippi for ten years, stepping down at the end of 2021 to pursue a desire to travel itinerantly 
        to equip the saints in Holy Spirit ministry and to encourage and strengthen leaders. They have been married over 39 years and 
        delight in their 4 children and 5 grandchildren
        `
    },
    {
        id: 17,
        name: "David Hinman",
        title: "Disciple-Making Movements (DMM) Specialist",
        img: "David.jpg",
        email:"davidh@vmteam.org",
        bio:`
        David has been a church planter and pastor in the Phoenix area over the past 25 years.  Currently, he is both a Movement 
        Catalyst with New Generations and an Associate Pastor at the Vineyard Gilbert.  David has worked extensively in training 
        and coaching individuals and ministries in Disciple Making Movements both in the U.S. and Oversees.  David has a B.S. in 
        Education from Taylor University and a Master of Divinity from Regent University.  David and his wife Lissa have three 
        children who are all married and two grandchildren.  David and Lissa’s favorite things do are to mountain bike, Kayak and 
        watch good movies.
        `
    },
    {
        id: 18,
        name: "Jim Egli",
        title: "Training Specialist",
        img: "Jim.jpg",
        email:"jime@vmteam.org",
        bio:`
        Jim has been a part of the Vineyard movement since 1992. He helps launch and equip Vineyard church planting movements in 
        West Africa and is the Curriculum Coordinator for New Generations. Together with his wife Vicki, he develops training and 
        resources for the worldwide Disciple Making Movement. It’s Jim's passion to multiply disciples, leaders, and churches around 
        the world, especially among unreached people groups. He has a Ph.D. in Organizational Communication with a cognate in World 
        Evangelization from Regent University.
        `
    },
    {
        id: 10,
        name: "Todd R.",
        title: "Fundraising Specialist",
        img: "Todd.jpg",
        email:"toddr@vmteam.org",
        bio:`
        Todd and his wife Michelle have been working in missions for over 25 years and have lived on foreign mission fields for 
        15 years. Todd is passionate about reaching unreached people groups. Having lived on support and raising funds for the 
        organization that they founded, Todd is passionate about not letting funding stop the mission of the Kingdom in people’s lives. 
        As a fundraising specialist he can share his experience, fundraising tools, and strategies that may assist with getting funding. 
        This subject can be stressful for many, but he looks forward to sharing the Biblical roots of fundraising and the joy of watching 
        God work in this necessary part of ministry.
        `
    },
    {
        id: 20,
        name: "Mark Fields",
        title: "Missions Consultant",
        img: "Mark.jpg",
        email:"mark@vmteam.org",
        bio:`
        Mark Fields currently serves as Missions Consultant for Vineyard USA. He previously worked for twenty-three years as the Director of Global & Intercultural Ministry for Vineyard USA. 
        Prior to assuming this position, he was the Senior Pastor of the Vineyard Community Church in Pomona, California, for more than twenty years. Mark is a lifelong learner and attended Fuller Seminary, 
        as well as the University of Balamand and the Jesuit College of Spiritualty. He has traveled and ministered extensively around the world. Mark and his wife Karen have been married for over forty years, 
        are the parents of three adult children, have a growing number of grandchildren, and reside in Southern California.
        `
    },
    {
        id: 21,
        name: "Christine Cottrell",
        title: "Administrator for Vineyard USA Missions",
        img: "Christine.jpg",
        email:"christine@vmteam.org",
        bio:`
     
        `
    },
    
]

export default people;