const schedule = [
    {
        id:0,
        day:"Wednesday - September 28th",
        date: new Date("2022", "08", "28"),
        sessions:[
            {
                timeOfDay: "afternoon",
                meetings:[
                    {
                        icon: "desktop-outline",
                        name: "Check In",
                        time: "3:00pm",
                        location:"Welcome Center"
                    }
                ]
            },
            {
                timeOfDay: "evening",
                meetings:[
                    {
                        icon: "restaurant-outline",
                        name: "Dinner",
                        time: "6:00pm",
                        location:"Buckboard Dining"
                    },
                    {
                        icon: "people-circle-outline",
                        name: "Session 1",
                        time: "7:30pm",
                        location:"Bluebonnet Room"
                    }
                ]
            }
        ]
        
    },
    {
        id:1,
        day:"Thursday - September 29th",
        date: new Date("2022", "08", "29"),
        sessions:[
            {
                timeOfDay:"morning",
                meetings:[
                    {
                        icon: "restaurant-outline",
                        name: "Breakfast",
                        time: "8:00am",
                        location:"Buckboard Dining"
                    },
                    {
                        icon: "people-circle-outline",
                        name: "Session 2",
                        time: "9:30am",
                        location:"Bluebonnet Room"
                    }   
                ]
            },
            {
                timeOfDay:"afternoon",
                meetings:[
                    {
                        icon: "restaurant-outline",
                        name: "Lunch",
                        time: "12:00pm",
                        location:"Buckboard Dining"
                    },
                    {
                        icon: "earth-outline",
                        name: "Regional Meetings",
                        time: "1:30pm",
                        location:"More Details",
                        hasDetails: true,
                        details:[
                            {
                                region:"South & East Asia Region",
                                location: "Bluebonnet Room"
                            },
                            {
                                region:"Central America Region",
                                location: "Primrose Room"
                            },
                            {
                                region:"South America Region",
                                location: "Firewheel Room"
                            },
                            {
                                region:"Sub Saharan African Region",
                                location: "Comal Meeting Room 56"
                            },
                            {
                                region:"Caribbean Region",
                                location: "Comal Meeting Room 55"
                            },
                            {
                                region:"MENACA Region",
                                location: "Comal Meeting Room 54"
                            },
                            {
                                region:"Europe",
                                location: "Comal Meeting Room 53"
                            }
                        ]
                    },  
                    {
                        icon: "footsteps-outline",
                        name: "Free Time",
                        time: "3:30pm",
                        location:""
                    } 
                ]
            },
            {
                timeOfDay:"evening",
                meetings:[
                    {
                        icon: "restaurant-outline",
                        name: "Dinner",
                        time: "6:00pm",
                        location:"Buckboard Dining"
                    },
                    {
                        icon: "people-circle-outline",
                        name: "Session 3",
                        time: "7:30pm",
                        location:"Bluebonnet Room"
                    }
                ]
            }
        ]                   

    },
    {
        id:2,
        day:"Friday - September 30th",
        date: new Date("2022", "08", "30"),
        sessions:[
            {
                timeOfDay:"morning",
                meetings:[
                    {
                        icon: "restaurant-outline",
                        name: "Breakfast",
                        time: "8:00am",
                        location:"Buckboard Dining"
                    },
                    {
                        icon: "people-circle-outline",
                        name: "Session 4",
                        time: "9:30am",
                        location:"Bluebonnet Room"
                    }   
                ]
            },
            {
                timeOfDay:"afternoon",
                meetings:[
                {
                    icon: "restaurant-outline",
                    name: "Lunch",
                    time: "12:00pm",
                    location:"Buckboard Dining"
                },
                {
                    icon: "earth-outline",
                    name: "Regional Meetings",
                    time: "1:30pm",
                    location:"More Details",
                    hasDetails: true,
                    details:[
                        {
                            region:"South & East Asia Region",
                            location: "Bluebonnet Room"
                        },
                        {
                            region:"Central America Region",
                            location: "Primrose Room"
                        },
                        {
                            region:"South America Region",
                            location: "Firewheel Room"
                        },
                        {
                            region:"Sub Saharan African Region",
                            location: "Comal Meeting Room 56"
                        },
                        {
                            region:"Caribbean Region",
                            location: "Comal Meeting Room 55"
                        },
                        {
                            region:"MENACA Region",
                            location: "Comal Meeting Room 54"
                        },
                        {
                            region:"Europe",
                            location: "Comal Meeting Room 53"
                        }
                    ]
                },  
                {
                    icon: "footsteps-outline",
                    name: "Free Time",
                    time: "3:30pm",
                    location:""
                }
                ]
            },
            {
                timeOfDay:"evening",
                meetings:[
                    {
                        icon: "restaurant-outline",
                        name: "Dinner",
                        time: "6:00pm",
                        location:"Buckboard Dining"
                    },
                    {
                        icon: "people-circle-outline",
                        name: "Session 5",
                        time: "7:30pm",
                        location:"Bluebonnet Room"
                    }
                ]
            }
        
        ]
        
    },
    {
        id:3,
        day:"Saturday - October 1st",
        date: new Date("2022", "09", "01"),
        sessions:[
            {
                timeOfDay:"morning",
                meetings:[
                    {
                        icon: "restaurant-outline",
                        name: "Dinner",
                        time: "8:00am",
                        location:"Buckboard Dining"
                    },
                    {
                        icon: "people-circle-outline",
                        name: "Session 6",
                        time: "9:30am",
                        location:"Bluebonnet Room"
                    },
                    {
                        icon: "airplane-outline",
                        name: "Departure",
                        time: "11:00am",                
                    }
                ]
            }
        ]
    
    }
]

export default schedule;