import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MainHeader.css";

function MainHeader(){    
    const navigate = useNavigate();
    const [activeLink, updateActiveLink] = useState(-1);
    useEffect(()=>{
        switch(window.location.pathname){
            case "/home/schedule" :
                updateActiveLink(0)
                break;
            case "/resources" :
                updateActiveLink(1)
                break;
            case "/maps" :
                updateActiveLink(2)
                break;
            default :
                updateActiveLink(0);
        }
    }, [])
    return(
        <div className="main-header">
            <section id="hero" className="">                
                <ul className="header-menu">
                    <li onClick={()=>{navigate("home/schedule"); updateActiveLink(0)}} className={activeLink === 0 ? "is-active" : ""}>
                        <ion-icon name="home"></ion-icon>&nbsp;&nbsp;&nbsp;
                        <Link to="/home/schedule">Home</Link>
                    </li>
                    <li onClick={()=>{navigate("resources"); updateActiveLink(1)}} className={activeLink === 1 ? "is-active" : ""}>
                        <ion-icon name="document-text"></ion-icon>&nbsp;&nbsp;&nbsp;
                        <Link to="/resources">Resources</Link>
                    </li>
                    <li onClick={()=>{navigate("maps"); updateActiveLink(2)}} className={activeLink === 2 ? "is-active" : ""}>
                        <ion-icon name="map"></ion-icon>&nbsp;&nbsp;&nbsp;
                        <Link to="/maps">Maps</Link>
                    </li>
                </ul>      
            </section>
            <header>
                <br/>
                <h1>Missions Leaders Meeting</h1>                
                <h2 className="mt-2">
                    <span>Out of the Wilderness
                         into the World</span></h2>
                
            </header>
            
        </div>
    );
}

export default MainHeader;