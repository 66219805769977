import schedule from "./scheduleData";
import people from "./peopleData";
import resources from "./resourcesData";
const data = {
    schedule,
    getSpeakers: getSpeakers,
    getMLT: getMLT,
    getResources,
    getMaps

}

function getResources(){
    const res = resources.filter(el=>{
        return el.type !== 2;
    })
    return res.reverse();
}

function getMaps(){
    const maps = resources.filter(el=>{
        return el.type === 2;
    })
    return maps;
}

function getSpeakers(){
    const peopleIDs = [0,1,2,3,4,5,6,7,8,9];
    const speakers = people.filter(el=>{
        return peopleIDs.indexOf(el.id) !== -1;
    })    
    
    return speakers;

}

function getMLT(){
    const mlt = people.filter(el=>el.id > 4);
    
    return mlt;
}
export default data;





